import { useState } from "react"
import { Box, Typography } from "@mui/material"
import { useHistory } from "react-router-dom"
import { Breadcrumb, DisplayMessage, Loader, Toggle } from "../../components"
import styles from './style.module.css'
import { OWNER_DETAILS, PARTS_DETAILS, ToggleOptions } from "./constants"
import OwnerDetailsForm from "./OwnerDetailsForm"
import PartsDetailsForm from "./PartsDetailsForm"
import { useFormik } from "formik"
import * as yup from 'yup'
import { sitesServices } from "../../services/SitesServices"
import * as messageTypes from '../../utils/messageTypes'
import _ from "lodash"

const ownerDetailsValidationSchema = yup.object({
  ownerName: yup.string().required('Name is required').min(2, 'Name must be min 2 characters').max(50, 'Name must be max 50 characters'),
  ownerEmail: yup.string().required('Email is required').email('Enter valid email'),
  country: yup.string().required('Country is required'),
  state: yup.string().required('State is required').min(2, 'State must be min 2 characters').max(30, 'State must be max 30 characters'),
  city: yup.string().required('City is required').min(2, 'City must be min 2 characters').max(30, 'City must be max 30 characters'),
  zip: yup.string().required('Zip is required').min(5, 'Zip must be minimum 5 characters').max(20, 'Zip must be maximum 20 characters'),
})

const partsDetailsValidationSchema = yup.object({
  microNumber: yup.string().min(2, 'Micro number must be min 2 characters').max(50, 'Micro number must be max 50 characters'),
  microCount: yup.string().min(0, 'Cannot be less than 0').max(50, 'Cannot exceed 50'),
  enchargeNumber: yup.string().min(2, 'Encharge number must be min 2 characters').max(50, 'Encharge number must be max 50 characters'),
  enchargeCount: yup.string().min(0, 'Cannot be less than 0').max(5, 'Cannot exceed 5'),
  relayNumber: yup.string().min(2, 'Relay number must be min 2 characters').max(50, 'Relay number must be max 50 characters'),
  relayCount: yup.string().min(0, 'Cannot be less than 0').max(1, 'Cannot exceed 1'),
  acbNumber: yup.string().min(2, 'Acb number must be min 2 characters').max(50, 'Acb number must be max 50 characters'),
  acbCount: yup.string().min(0, 'Cannot be less than 0').max(5, 'Cannot exceed 5'),
  envoyNumber: yup.string().min(2, 'Envoy number must be min 2 characters').max(50, 'Envoy number must be max 50 characters'),
  envoyCount: yup.string().min(0, 'Cannot be less than 0').max(1, 'Cannot exceed 1'),
  enpowerNumber: yup.string().min(2, 'enpower number must be min 2 characters').max(50, 'enpower number must be max 50 characters'),
  enpowerCount: yup.string().min(0, 'Cannot be less than 0').max(2, 'Cannot exceed 2'),
})

const SiteForm = () => {
  const [loading, setLoading] = useState(false)
  const [selectedToggle, setSelectedToggle] = useState(OWNER_DETAILS)
  const [ownerFormFilled, setOwnerFormFilled] = useState(false)
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState()
  const [messageType, setMessageType] = useState()

  const history = useHistory()

  const ownerDetailsFormik = useFormik({
    initialValues: {
      ownerName: '',
      ownerEmail: '',
      country: 'United States',
      state: '',
      city: '',
      zip: '',
      sitesCount: 10
    },
    validationSchema: ownerDetailsValidationSchema,
    onSubmit: (values) => {
      setOwnerFormFilled(true)
      setSelectedToggle(PARTS_DETAILS)
    }
  })

  const partsDetailsFormik = useFormik({
    initialValues: {
      microNumber: '',
      microCount: 10,
      enchargeNumber: '',
      enchargeCount: 1,
      relayNumber: '',
      relayCount: 0,
      acbNumber: '',
      acbCount: 0,
      envoyNumber: '',
      envoyCount: 1,
      enpowerNumber: '',
      enpowerCount: 0
    },
    validationSchema: partsDetailsValidationSchema,
    onSubmit: (values) => {
      createSites({ ...ownerDetailsFormik.values, ...values })
    }
  })

  const createSites = (payload) => {
    setLoading(true)
    sitesServices.createSites(payload)
      .then(sitesData => {
        setLoading(false)
        setOpen(true)
        setMessage('Site creation request submitted successfully')
        setMessageType(messageTypes.SUCCESS)
        setTimeout(() => history.push('/dashboard'), 2000)
      })
      .catch(error => {
        setLoading(false)
        setOpen(true)
        setMessage(_.get(error, 'data.error.displayMessage', 'Something went wrong'))
        setMessageType(messageTypes.ERROR)
      })
  }

  return (
    <Box>
      <Loader loading={loading} />
      <Breadcrumb value={[{ 'System Dashboard': '/dashboard' }, { 'Create Site': `/create-site-form` }]} />
      <DisplayMessage open={open} message={message} messageType={messageType} handleClose={() => setOpen(false)} />
      <Box className={styles.headerWrapper} display="flex" flexWrap="wrap">
        <Typography className={styles.title}>{'Create Request'}</Typography>
      </Box>
      <Box className={styles.headerWrapper} display="flex" flexWrap="wrap" mt={2}>
        <Toggle
          list={ToggleOptions(ownerFormFilled)}
          selected={selectedToggle}
          onChange={(id) => {
            if (ownerFormFilled)
              setSelectedToggle(id)
          }}
        />
      </Box>
      <Box className={styles.formContainer}>
        {selectedToggle === OWNER_DETAILS ?
          <OwnerDetailsForm loading={loading} formik={ownerDetailsFormik} /> :
          <PartsDetailsForm loading={loading} formik={partsDetailsFormik} />
        }
      </Box>
    </Box>)
}

export default SiteForm