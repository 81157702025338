import { Box, Divider, Grid, TextField } from "@mui/material"
import styles from './style.module.css'
import { connect } from "react-redux"
import { getErrors, getHelperText, renderIcon } from "../../utils/formikHelper"
import { AppButton, CustomSlider } from "../../components"
import { acbMarks, enpowerMarks, enchargeMarks, envoyMarks, microMarks } from "./constants"

const PartsDetailsForm = (props) => {
  const { formik, currentUser } = props

  const isEnphaseUser = currentUser?.user_email?.split('@')[1] === 'enphaseenergy.com'

  return (
    <Box display="flex" flexDirection="column" minHeight="65vh">
      <Box flexGrow="1">
        <form onSubmit={formik.handleSubmit} id="partsDetails">
          <Grid container spacing={{ xs: 0, md: 4 }}>
            <Grid item spacing={0} xs={12} md={6}>
              <Box mx={1} my={2}>
                <Box>
                  {isEnphaseUser && <TextField
                    fullWidth
                    className={styles.formInput}
                    label="Micro Part Number"
                    name="microNumber"
                    variant="standard"
                    placeholder="Enter Value"
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    value={formik.values.microNumber}
                    InputProps={{
                      endAdornment: renderIcon(formik.errors.microNumber, formik.values.microNumber, formik.touched.microNumber),
                    }}
                    error={getErrors(formik.errors.microNumber, formik.values.microNumber, formik.touched.microNumber)}
                    helperText={getHelperText(formik.errors.microNumber, formik.values.microNumber, formik.touched.microNumber)}
                  />}
                </Box>
                <Box className={styles.sliderWrapper}>
                  <Box className={styles.sliderText}>Micro Count</Box>
                  <Box display="flex">
                    <CustomSlider defaultValue={10} min={0} max={50} marks={microMarks} onChange={(e, value) => formik.setFieldValue('microCount', value)} />
                    <Box className={styles.sliderValueBox}>{formik.values.microCount}</Box>
                  </Box>
                </Box>
              </Box>
              <Box mx={1} my={2}>
                <Box>
                  {isEnphaseUser && <TextField
                    fullWidth
                    className={styles.formInput}
                    label="Encharge Part Number"
                    name="enchargeNumber"
                    variant="standard"
                    placeholder="Enter Value"
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    value={formik.values.enchargeNumber}
                    InputProps={{
                      endAdornment: renderIcon(formik.errors.enchargeNumber, formik.values.enchargeNumber, formik.touched.enchargeNumber),
                    }}
                    error={getErrors(formik.errors.enchargeNumber, formik.values.enchargeNumber, formik.touched.enchargeNumber)}
                    helperText={getHelperText(formik.errors.enchargeNumber, formik.values.enchargeNumber, formik.touched.enchargeNumber)}
                  />}
                </Box>
                <Box className={styles.sliderWrapper}>
                  <Box className={styles.sliderText}>Encharge Count</Box>
                  <Box display="flex">
                    <CustomSlider defaultValue={1} min={0} max={5} marks={enchargeMarks} onChange={(e, value) => formik.setFieldValue('enchargeCount', value)} />
                    <Box className={styles.sliderValueBox}>{formik.values.enchargeCount}</Box>
                  </Box>
                </Box>
              </Box>
              <Box mx={1} my={2}>
                <Box>
                  {isEnphaseUser && <TextField
                    fullWidth
                    className={styles.formInput}
                    label="IQ Relay Part Number"
                    name="relayNumber"
                    variant="standard"
                    placeholder="Enter Value"
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    value={formik.values.relayNumber}
                    InputProps={{
                      endAdornment: renderIcon(formik.errors.relayNumber, formik.values.relayNumber, formik.touched.relayNumber),
                    }}
                    error={getErrors(formik.errors.relayNumber, formik.values.relayNumber, formik.touched.relayNumber)}
                    helperText={getHelperText(formik.errors.relayNumber, formik.values.relayNumber, formik.touched.relayNumber)}
                  />}
                </Box>
                <Box className={styles.sliderWrapper}>
                  <Box className={styles.sliderText}>IQ Relay Count</Box>
                  <Box display="flex">
                    <CustomSlider defaultValue={0} min={0} max={1} marks={enchargeMarks} onChange={(e, value) => formik.setFieldValue('relayCount', value)} />
                    <Box className={styles.sliderValueBox}>{formik.values.relayCount}</Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mx={1} my={2}>
                <Box>
                  {isEnphaseUser && <TextField
                    fullWidth
                    className={styles.formInput}
                    label="ACBs Number"
                    name="acbNumber"
                    variant="standard"
                    placeholder="Enter Value"
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    value={formik.values.acbNumber}
                    InputProps={{
                      endAdornment: renderIcon(formik.errors.acbNumber, formik.values.acbNumber, formik.touched.acbNumber),
                    }}
                    error={getErrors(formik.errors.acbNumber, formik.values.acbNumber, formik.touched.acbNumber)}
                    helperText={getHelperText(formik.errors.acbNumber, formik.values.acbNumber, formik.touched.acbNumber)}
                  />}
                </Box>
                <Box className={styles.sliderWrapper}>
                  <Box className={styles.sliderText}>ACBs Count</Box>
                  <Box display="flex">
                    <CustomSlider defaultValue={0} min={0} max={5} marks={acbMarks} onChange={(e, value) => formik.setFieldValue('acbCount', value)} />
                    <Box className={styles.sliderValueBox}>{formik.values.acbCount}</Box>
                  </Box>
                </Box>
              </Box>
              <Box mx={1} my={2}>
                <Box>
                  {isEnphaseUser && <TextField
                    fullWidth
                    className={styles.formInput}
                    label="Envoy Part Number"
                    name="envoyNumber"
                    variant="standard"
                    placeholder="Enter Value"
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    value={formik.values.envoyNumber}
                    InputProps={{
                      endAdornment: renderIcon(formik.errors.envoyNumber, formik.values.envoyNumber, formik.touched.envoyNumber),
                    }}
                    error={getErrors(formik.errors.envoyNumber, formik.values.envoyNumber, formik.touched.envoyNumber)}
                    helperText={getHelperText(formik.errors.envoyNumber, formik.values.envoyNumber, formik.touched.envoyNumber)}
                  />}
                </Box>
                <Box className={styles.sliderWrapper}>
                  <Box className={styles.sliderText}>Envoy Count</Box>
                  <Box display="flex">
                    <CustomSlider defaultValue={1} min={0} max={1} marks={envoyMarks} disabled onChange={(e, value) => formik.setFieldValue('envoyCount', value)} />
                    <Box className={styles.sliderValueBox}>{formik.values.envoyCount}</Box>
                  </Box>
                </Box>
              </Box>
              <Box mx={1} my={2}>
                <Box>
                  {isEnphaseUser && <TextField
                    fullWidth
                    className={styles.formInput}
                    label="Enpower Part Number"
                    name="enpowerNumber"
                    variant="standard"
                    placeholder="Enter Value"
                    InputLabelProps={{ shrink: true }}
                    onChange={formik.handleChange}
                    value={formik.values.enpowerNumber}
                    InputProps={{
                      endAdornment: renderIcon(formik.errors.enpowerNumber, formik.values.enpowerNumber, formik.touched.enpowerNumber),
                    }}
                    error={getErrors(formik.errors.enpowerNumber, formik.values.enpowerNumber, formik.touched.enpowerNumber)}
                    helperText={getHelperText(formik.errors.enpowerNumber, formik.values.enpowerNumber, formik.touched.enpowerNumber)}
                  />}
                </Box>
                <Box className={styles.sliderWrapper}>
                  <Box className={styles.sliderText}>Enpower Count</Box>
                  <Box display="flex">
                    <CustomSlider defaultValue={0} min={0} max={1} marks={enpowerMarks} onChange={(e, value) => formik.setFieldValue('enpowerCount', value)} />
                    <Box className={styles.sliderValueBox}>{formik.values.enpowerCount}</Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Divider />
      <Box display="flex" justifyContent="flex-end">
        <AppButton className={styles.submitButton} type="submit" text="Create" form="partsDetails" />
      </Box>
    </Box>
  )
}

const mapStateToProps = ({ auth }) => {
  const { currentUser } = auth
  return { currentUser }
}

export default connect(mapStateToProps, {})(PartsDetailsForm)