import { Box, Divider, Grid, TextField } from "@mui/material"
import styles from './style.module.css'
import { getErrors, getHelperText, renderIcon } from "../../utils/formikHelper"
import { AppButton, CustomSlider } from "../../components"
import { sitesMarks } from "./constants"

const OwnerDetailsForm = (props) => {
  const { formik } = props
  return (
    <Box display="flex" flexDirection="column" height="60vh">
      <Box flexGrow="1">
        <form onSubmit={formik.handleSubmit} id="ownerDetails">
          <Grid container spacing={{ xs: 0, md: 4 }}>
            <Grid item spacing={0} xs={12} md={6}>
              <Box className={styles.sliderWrapper}>
                <Box className={styles.sliderText}>Number of Sites</Box>
                <Box display="flex">
                  <CustomSlider defaultValue={10} min={1} max={50} marks={sitesMarks} onChange={(e, value) => formik.setFieldValue('sitesCount', value)} />
                  <Box className={styles.sliderValueBox}>{formik.values.sitesCount}</Box>
                </Box>
              </Box>
            </Grid>
            <Grid item spacing={0} xs={12} md={6}></Grid>
            <Grid item spacing={0} xs={12} md={6}>
              <Box m={1}>
                <TextField
                  fullWidth
                  className={styles.formInput}
                  label="Owner Name"
                  name="ownerName"
                  variant="standard"
                  onChange={formik.handleChange}
                  value={formik.values.ownerName}
                  InputProps={{
                    endAdornment: renderIcon(formik.errors.ownerName, formik.values.ownerName, formik.touched.ownerName),
                  }}
                  error={getErrors(formik.errors.ownerName, formik.values.ownerName, formik.touched.ownerName)}
                  helperText={getHelperText(formik.errors.ownerName, formik.values.ownerName, formik.touched.ownerName)}
                />
              </Box>
              <Box m={1}>
                <TextField
                  fullWidth
                  className={styles.formInput}
                  label="Owner Email"
                  name="ownerEmail"
                  variant="standard"
                  onChange={formik.handleChange}
                  value={formik.values.ownerEmail}
                  InputProps={{
                    endAdornment: renderIcon(formik.errors.ownerEmail, formik.values.ownerEmail, formik.touched.ownerEmail),
                  }}
                  error={getErrors(formik.errors.ownerEmail, formik.values.ownerEmail, formik.touched.ownerEmail)}
                  helperText={getHelperText(formik.errors.ownerEmail, formik.values.ownerEmail, formik.touched.ownerEmail)}
                />
              </Box>
              <Box mt={1} mx={1}>
                <TextField
                  fullWidth
                  className={styles.formInput}
                  label="Country"
                  name="country"
                  variant="standard"
                  disabled
                  value={formik.values.country}
                  InputProps={{
                    endAdornment: renderIcon(formik.errors.country, formik.values.country, formik.touched.country),
                  }}
                  error={getErrors(formik.errors.country, formik.values.country, formik.touched.country)}
                  helperText={getHelperText(formik.errors.country, formik.values.country, formik.touched.country)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box m={1}>
                <TextField
                  fullWidth
                  className={styles.formInput}
                  label="State"
                  name="state"
                  variant="standard"
                  onChange={formik.handleChange}
                  value={formik.values.state}
                  InputProps={{
                    endAdornment: renderIcon(formik.errors.state, formik.values.state, formik.touched.state),
                  }}
                  error={getErrors(formik.errors.state, formik.values.state, formik.touched.state)}
                  helperText={getHelperText(formik.errors.state, formik.values.state, formik.touched.state)}
                />
              </Box>
              <Box m={1}>
                <TextField
                  fullWidth
                  className={styles.formInput}
                  label="City"
                  name="city"
                  variant="standard"
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  InputProps={{
                    endAdornment: renderIcon(formik.errors.city, formik.values.city, formik.touched.city),
                  }}
                  error={getErrors(formik.errors.city, formik.values.city, formik.touched.city)}
                  helperText={getHelperText(formik.errors.city, formik.values.city, formik.touched.city)}
                />
              </Box>
              <Box m={1}>
                <TextField
                  fullWidth
                  className={styles.formInput}
                  label="Zip"
                  name="zip"
                  variant="standard"
                  onChange={formik.handleChange}
                  value={formik.values.zip}
                  InputProps={{
                    endAdornment: renderIcon(formik.errors.zip, formik.values.zip, formik.touched.zip),
                  }}
                  error={getErrors(formik.errors.zip, formik.values.zip, formik.touched.zip)}
                  helperText={getHelperText(formik.errors.zip, formik.values.zip, formik.touched.zip)}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Divider />
      <Box display="flex" justifyContent="flex-end">
        <AppButton className={styles.submitButton} type="submit" text="Next" form="ownerDetails" />
      </Box>
    </Box>
  )
}

export default OwnerDetailsForm