import React from 'react'

function CircleTick() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path fill="#35c37d" d="M8,16A8,8,0,0,1,2.343,2.343,8,8,0,1,1,13.656,13.656,7.947,7.947,0,0,1,8,16ZM4.681,7.173a.752.752,0,0,0-.564.235.833.833,0,0,0,0,1.155l.675.677,1.351,1.354q.141.143.286.287a.829.829,0,0,0,1.155,0l.487-.488.978-.977L11.385,7.08l.534-.535a.835.835,0,0,0,.235-.607.756.756,0,0,0-.235-.549.87.87,0,0,0-.59-.233.8.8,0,0,0-.565.233L9.3,6.854,7.007,9.148l-.412-.414Q6.076,8.215,5.558,7.7q-.141-.143-.286-.287A.836.836,0,0,0,4.681,7.173Z" transform="translate(0 0)" />
    </svg>
  )
}

export default CircleTick