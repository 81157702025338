import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { orange, grey, blue } from '@mui/material/colors'


let theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: 'Roboto, sans-serif'
  },
  palette: {
    primary: {
      main: '#F3731F',
      dark: '#335977',
      yellow: '#FFE785',
      faded: '#C9C9C9',
      lightBlack: '#5C5C5C',
      dataBlack: '#999999',
      white: '#FFFFFF',
      mainText: '#545456',
      subText: '#939598',
      disableBg: '#F6F6F6',
      ...orange
    },
    secondary: {
      main: '#01B4DE',
      link: '#2679f6',
      ...blue
    },
    tertiary: {
      main: '#FFFFFF',
      light: '#FAFAFA',
      dark: '#7E7E7E',
      background: '#F6F7FB',
      lighter: grey[100],
      ...grey
    }
  },
  overrides: {
    MuiStepIcon: {
      text: {
        fontSize: '13px',
        '&$active': {
          // color: '#F3731F'
        },

        fontWeight: 'bold',
        fill: '#FFFFFF'
      },
      root: {
        '&$active': {
          // color: '#F3731F'
        }
      }
    }
  }
})

theme = responsiveFontSizes(theme)

export default theme
