import { useState } from 'react'
import { Box, Checkbox, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Popover, TextField } from '@mui/material'
import { ALL_COLUMNS } from './constants'
import styles from './style.module.css'
import SearchIcon from '@mui/icons-material/Search'

const not = (a, b) => {
  return a.filter((value) => b.indexOf(value) === -1)
}

const intersection = (a, b) => {
  return a.filter((value) => b.indexOf(value) !== -1)
}

function union(a, b) {
  return [...a, ...not(b, a)]
}

const ColumnSelector = (props) => {

  const { id, open, anchorEl, handlePopoverClose, columns, setColumns } = props

  const [checked, setChecked] = useState(columns)
  const [allColumns, setAllColumns] = useState(ALL_COLUMNS)

  const numberOfChecked = (allColumns) => intersection(checked, allColumns).length

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  const handleToggleAll = (items) => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  const handleSearch = (e) => {
    const columns = ALL_COLUMNS.filter(col => col.toLowerCase().includes(e.target.value?.toLowerCase()))
    setAllColumns(columns)
  }

  const customList = (items) => (
    <Paper sx={{ width: "100%", height: 230, overflow: 'auto' }} elevation={0}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`
          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon sx={{ minWidth: '20px' }}>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  size="small"
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItem>
          )
        })}
      </List>
    </Paper>
  )

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={() => { setColumns(checked); setAllColumns(ALL_COLUMNS); handlePopoverClose() }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box className={styles.popoverHeading} m={2}>
        <TextField
          fullWidth
          placeholder='Search columns'
          size='small'
          onChange={handleSearch}
          InputProps={{
            endAdornment: <><SearchIcon /></>,
          }}
        />
      </Box>
      <Divider />
      <List dense component="div" role="list" disablePadding>
        <ListItem
          key={'Toggle All'}
          role="listitem"
          button
          onClick={() => handleToggleAll(ALL_COLUMNS)}
        >
          <ListItemIcon sx={{ minWidth: '20px' }}>
            <Checkbox
              checked={numberOfChecked(ALL_COLUMNS) === ALL_COLUMNS.length}
              size="small"
              indeterminate={numberOfChecked(ALL_COLUMNS) !== ALL_COLUMNS.length && numberOfChecked(ALL_COLUMNS) !== 0}
            />
          </ListItemIcon>
          <ListItemText id={'toggle_all'} primary={'Toggle All'} />
        </ListItem>
      </List>
      <Divider />
      <Grid container>
        <Grid item xs={12}>
          {customList(allColumns)}
        </Grid>
      </Grid>
    </Popover>
  )
}

export default ColumnSelector