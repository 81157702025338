import { useState } from "react"
import { Box, Slider, styled } from "@mui/material"
import styles from './style.module.css'

const PrettoSlider = styled(Slider)({
  color: '#F37320',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '&	.MuiSlider-rail': {
    backgroundColor: '#A9A9AA',
    opacity: '0.2'
  },
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '5px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#bfbfbf',
    height: 15,
    width: 1,
    top: 20,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
})

const CustomSlider = (props) => {
  const { defaultValue, min, max, marks, disabled, onChange } = props
  const [value, setValue] = useState(defaultValue)

  return (
    <PrettoSlider
      aria-label="custom slider"
      defaultValue={defaultValue}
      min={min}
      max={max}
      marks={marks}
      disabled={disabled}
      onChange={onChange}
    />
  )
}

CustomSlider.defaultProps = {
  defaultValue: 50,
  min: 0,
  max: 100,
  marks: false
}

export default CustomSlider