import { Button } from '@mui/material'
import { forwardRef } from 'react'
import styles from './style.module.css'

const AppButton = forwardRef((props, ref) => {

  let {
    text,
    fill,
    disabled
  } = props

  return <>
    <Button
      {...props}
      ref={ref}
      disableRipple
      disableFocusRipple
      className={styles.ButtonBox + (fill ? '' : (' ' + styles.PlainButtonBox)) + (` ${props.className}` || '') + (disabled ? ` ${styles.disable}` : '')}
    >
      {text}
    </Button>
  </>
})

AppButton.defaultProps = {
  text: "",
  fill: true
}

export default AppButton