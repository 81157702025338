import React from 'react'
import PropTypes from 'prop-types'
import SVG from '../components/Icons'

export default class Icon extends React.Component {
  static defaultProps = {
    className: 'icon',
    type: 'svg',
    width: 100,
    height: 100
  };

  static propTypes = {
    className: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
  };

  constructor() {
    super()

    this.TYPE = {
      SVG: 'svg'
    }
  }

  render() {
    const { className, src } = this.props
    const InlineSVG = SVG[src]

    return (
      <React.Fragment>
        <InlineSVG className={`icon ${className !== '' ? `icon-${className}` : ''}`} {...this.props} />
      </React.Fragment>
    )
  }
}
