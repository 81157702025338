import "./landing_page.css"

import Navbar from "../../components/navbar/navbar"
import JobRequest from "../../components/job-request/job-request"
import LogOut from "../../components/logout/logout_comp"
import EnvoyDetails from "../../components/envoy-details-view/envoy_details"
import ReactPaginate from "react-paginate"
import { useState, useEffect } from "react"
import { Redirect, useHistory } from "react-router-dom"

function LandingPage() {
  const [newRequestPopup, setnewRequestPopup] = useState(false)
  const [logOutPopup, setlogOutPopup] = useState(false)
  const [envoyViewPopup, setenvoyViewPoppup] = useState(false)

  const [requestId, setrequestId] = useState(1)
  const [state, setstate] = useState(true)

  const [data, setdata] = useState([])
  const [fulldata, setfulldata] = useState([])
  const [recordsPerPage] = useState(5)
  const [nPages, setnPages] = useState(0)

  const history = useHistory()

  const changeData = ({ selected }) => {
    const indexOfLastRecord = (selected + 1) * recordsPerPage
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
    setdata(fulldata.slice(indexOfFirstRecord, indexOfLastRecord))
  }
  const headers = ["Request Id", "Envoy", "Pending Inv",
    "Completed Inv", "Battery Mode", "Created", "Expiry"]

  useEffect(() => {
    async function get_data() {
      if (localStorage.getItem("envoy_twin_auth_token") == null) {
        setstate(false)
        return
      }
      const response = await fetch(process.env.REACT_APP_ENVOY_TWIN_END_POINT + "/e2/fetch_user_details",
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("envoy_twin_auth_token"),
          }
        })
      if (Math.floor(response.status / 100) !== 2) {
        setstate(false)
      }
      else {
        const json_response = await response.json()
        setfulldata(json_response.request_summary)
        setdata(json_response.request_summary.slice(0, recordsPerPage))
        setnPages(Math.ceil(json_response.request_summary.length / recordsPerPage))
      }
    }
    get_data()
  }, [recordsPerPage])

  function RequestEnvoyPopUp(request_id) {
    setrequestId(request_id)
    setenvoyViewPoppup(true)
  }

  function triggerLogoutPopup() {
    setlogOutPopup(true)
  }

  return state ? (
    <div className="container-center-landing-page">
      <div className="landing-page screen">
        <Navbar loginenabled={true} email_id={localStorage.getItem("envoy_twin_email_id")}
          triggerlogout={triggerLogoutPopup} />
        <h1 className="envoy-heading enphasevisuelt-medium-black-32px">
          <span className="enphasevisuelt-medium-black-32px">Envoy Twin</span>
        </h1>
        <button className="new-request-button">
          <div className="request-button enphasevisuelt-medium-white-16px"
            onClick={() => history.push('create-site-form')}>
            <span className="enphasevisuelt-medium-white-16px">New Request</span>
          </div>
        </button>
        <div className="request-summary">
          <table className="table-request-summary">
            <thead className="thead-request-summary">
              <tr className="trHead">
                {headers.map((item, index) => (
                  <th className="enphasevisuelt-medium-black-20px" key={index}>
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((obj, index) => (
                <tr className="trBody">
                  <td className="td-request-summary enphasevisuelt-medium-black-20px">
                    {obj.request_id}
                  </td>
                  <td className="td-request-summary details-css enphasevisuelt-medium-black-20px" onClick={() =>
                    RequestEnvoyPopUp(obj.request_id)}>
                    Details
                  </td>
                  <td className="td-request-summary enphasevisuelt-medium-black-20px">
                    {obj.pending_inv_jobs}
                  </td>
                  <td className="td-request-summary enphasevisuelt-medium-black-20px" >
                    {obj.completed_inv_jobs}
                  </td>
                  <td className="td-request-summary enphasevisuelt-medium-black-20px">
                    {obj.battery_mode}
                  </td>
                  <td className="td-request-summary enphasevisuelt-medium-black-20px" >
                    {obj.created_at}
                  </td>
                  <td className="td-request-summary enphasevisuelt-medium-black-20px" >
                    {obj.expiry}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <br />
        <br />
        {nPages > 1 ?
          <ReactPaginate previousLabel={"Previous"} nextLabel={"Next"} pageCount={nPages}
            onPageChange={changeData} containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"} /> : ""
        }
      </div>
      <JobRequest trigger={newRequestPopup} settrigger={setnewRequestPopup} />
      <LogOut trigger={logOutPopup} settrigger={setlogOutPopup} />
      <EnvoyDetails request_id={requestId} trigger={envoyViewPopup} settrigger={setenvoyViewPoppup} />
    </div>
  ) : <Redirect to="/" />
}

export default LandingPage
