import React, { useRef, useEffect } from 'react'
import styles from './style.module.css'
import MuiAlert from '@mui/material//Alert'
import { Snackbar } from '@mui/material/'


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})
const DisplayMessage = (props) => {
  const outerRef = useRef()

  const handleOuterClick = (e) => {
    if (!outerRef.current.contains(e.target)) {
      props.handleClose()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOuterClick)
    return () => {
      document.removeEventListener('mousedown', handleOuterClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.root} ref={outerRef}>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={props.open} autoHideDuration={6000} onClose={props.handleClose} key={'top center'}>
        <Alert onClose={props.handleClose} severity={props.messageType}>
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default DisplayMessage