import axios from 'axios'
import { getToken, getSessionToken } from './helper'
import { removeLocalStorage, removeSessionStorage } from './localStorage'

const service = axios.create({})

service.interceptors.request.use(
  (config) => {
    if (!config.data) {
      config.headers['Content-Type'] = ''
    }
    let token = getSessionToken() || getToken()
    config.headers.Authorization = 'Bearer ' + token
    return config
  },
  (error) => {
    Promise.reject(error)
  },
)
service.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 544 || error.response.status === 545 || error.response.status === 533) {
      removeLocalStorage('auth')
      removeSessionStorage('auth')
      removeLocalStorage('session_id')
      window.location = '/'
    }
    return Promise.reject(error.response)
  },
)

export default service
