import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router"
import { Box, Paper, TextField } from "@mui/material"
import styles from './style.module.css'
import { Form, Formik } from "formik"
import { userSignIn, clearMessage } from '../../appRedux/actions/Auth'
import * as yup from 'yup'
import { AppButton, DisplayMessage, Loader } from "../../components"

const validationSchema = yup.object({
  emailId: yup.string().required('Email is required').email('Enter valid email'),
})

const Signin = (props) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (props.message) {
      setOpen(true)
      const clear = setTimeout(() => {
        props.clearMessage()
      }, 6000)
      return () => clearTimeout(clear)
    }
  }, [props.message])

  useEffect(() => {
    if (!open) {
      props.clearMessage()
    }
  }, [open])

  if (props.verifiedEmail) {
    return <Redirect to={{ pathname: '/verify-otp' }} />
  }

  return (
    <div className={styles.container} >
      <Loader loading={props.loading} />
      {props.message && props.messageType && <DisplayMessage open={open} message={props.message} messageType={props.messageType} handleClose={() => setOpen(false)} />}
      <Box display="flex" flexWrap="wrap" justifyContent="space-around" alignContent="center" minHeight="94vh" height="100%">
        <Paper className={styles.signin}>
          <div className={styles.root}>
            <Box className={styles.signInTextBox} p={1}>
              Login
            </Box>
          </div>
          <hr />
          <Box p={1}>
            <Formik
              initialValues={{
                emailId: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                props.userSignIn(values)
              }}
            >
              {(formik) => (
                <Form>
                  <Box m={2}>
                    <TextField
                      fullWidth
                      label="Email Id"
                      name="emailId"
                      variant="standard"
                      onChange={formik.handleChange}
                      value={formik.values.emailId}
                      error={formik.touched.emailId && Boolean(formik.errors.emailId)}
                      helperText={formik.touched.emailId && formik.errors.emailId}
                    />
                  </Box>
                  <Box display="flex" justifyContent="center" mt={5} mb={2}>
                    <AppButton type="submit" text="Send OTP" />
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Paper>
      </Box>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const { authUser, verifiedEmail, message, messageType, loading } = auth
  return { authUser, verifiedEmail, message, messageType, loading }
}

const mapDispatchToProps = dispatch => ({
  userSignIn: payload => dispatch(userSignIn(payload)),
  clearMessage: () => dispatch(clearMessage())
})

export default connect(mapStateToProps, mapDispatchToProps)(Signin)