import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { getCurrentUser } from '../appRedux/actions/Auth'
import {
  Dashboard,
  LandingPage,
  SiteForm
} from "../layouts"
import { Loader } from '../components'

class AppRoutes extends Component {
  render() {
    const { authUser, currentUser, getCurrentUser } = this.props
    if (!currentUser) {
      getCurrentUser(authUser)
    }

    return (
      currentUser ?
        <Switch>
          <Route exact path="/dashboard" component={Dashboard} isSignedInUser />
          <Route exact path='/create-site-form' component={SiteForm} isSignedInUser />
        </Switch>
        : <Loader loading={true} />
    )
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser, currentUser } = auth
  return { authUser, currentUser }
}

const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: () => dispatch(getCurrentUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes)
