import { all, fork } from 'redux-saga/effects'
import {
  signInWithDetails,
  signOutUser,
  getCurrentUser,
  verifyOTP,
} from './Auth'

export default function* root() {
  yield all([
    fork(signInWithDetails),
    fork(verifyOTP),
    fork(signOutUser),
    fork(getCurrentUser),
  ])
}
