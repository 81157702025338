import "./heading.css"

function Heading(props) {
    const { heading } = props;

    return (
        <h1 className="heading enphase-regular-normal-black-64px">
            <span className="enphasevisuelt-regural-normal-black-64px">{heading}</span>
        </h1>
    );
}

export default Heading;
