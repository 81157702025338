import { assign } from 'lodash'

const development = {
  api: 'https://envoy-twin-dev.qa-enphaseenergy.com',
}

const test = {
  api: 'https://envoy-twin-dev.qa-enphaseenergy.com',
}

const qa4 = {
  api: 'https://envoy-twin-dev.qa-enphaseenergy.com',
}

const rel = {
  api: 'https://envoy-twin-rel.qa-enphaseenergy.com',
}

const qa2 = {
  api: 'https://envoy-twin.qa-enphaseenergy.com',
}

const production = {
  api: 'https://envoy-twin.enphaseenergy.com',
}

const preprod = {
  api: 'https://envoy-twin-preprod.enphaseenergy.com',
}

const local = {
  api: 'http://localhost:8080',
}

const DEFAULT = {}

const CONFIG = {
  development,
  production,
  preprod,
  test,
  qa2,
  rel,
  qa4,
  local,
}

const getConfig = (env) => {
  let hostname
  if (typeof window !== 'undefined') {
    hostname = window.location.hostname
    if (hostname.search('envoy-twin.enphaseenergy.com') >= 0) {
      env = 'production'
    } else if (hostname.search('envoy-twin-preprod.enphaseenergy.com') >= 0) {
      env = 'preprod'
    } else if (hostname.search('envoy-twin-qa2.qa-enphaseenergy.com') >= 0) {
      env = 'qa2'
    } else if (hostname.search('envoy-twin-rel.qa-enphaseenergy.com') >= 0) {
      env = 'rel'
    } else if (hostname.search('envoy-twin-qa4.qa-enphaseenergy.com') >= 0) {
      env = 'qa4'
    } else if (window.location.port === '8002') {
      env = 'qa4'
    } else {
      env = 'qa4'
    }
  } else if (env === 'test') {
    env = 'test'
  }

  const envConfig = CONFIG[env]
  if (envConfig) {
    return assign({}, DEFAULT, envConfig)
  }
  throw new Error('Unknown environment : ' + env)
}

export default getConfig
