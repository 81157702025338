import device_types from '../../utils/constants'
import styles from './style.module.css'

const SYSTEM_ID = 'System Id'
const SYSTEM_NAME = 'System Name'
const REQUEST_ID = 'Request Id'
const SYSTEM_TYPE = 'System Type'
const OWNER_NAME = 'Owner Name'
const OWNER_EMAIL = 'Owner Email'
const CITY = 'City'
const STATE = 'State'
const COUNTRY = 'Country'
const ZIP = 'Zip'
const MICRO_PART_NUMBER = 'Micro Part Number'
const MICRO_COUNT = 'Micro Count'
const ENCHARGE_PART_NUMBER = 'Encharge Part Number'
const ENCHARGE_COUNT = 'Encharge Count'
const IQ_RELAY_PART_NUMBER = 'IQ Relay Part Number'
const IQ_RELAY_COUNT = 'IQ Relay Count'
const ACB_PART_NUMBER = 'ACB Part Number'
const ACB_COUNT = 'ACB Count'
const ENVOY_PART_NUMBER = 'Envoy Part Number'
const ENVOY_COUNT = 'Envoy Count'
const ENPOWER_PART_NUMBER = 'Enpower Part Number'
const ENPOWER_COUNT = 'Enpower Count'

const tableHeadings = (columns) => ([
  ...(columns.includes(SYSTEM_ID) ?
    [{
      id: 'systemId',
      header: 'SYSTEM ID',
      className: styles.systemIdHeader,
      valuePath: 'site_id'
    }] : []),
  ...(columns.includes(SYSTEM_NAME) ?
    [{
      id: 'systemName',
      header: 'SYSTEM NAME',
      className: styles.systemNameHeader,
      valuePath: 'job_id'
    }] : []),
  ...(columns.includes(REQUEST_ID) ?
    [{
      id: 'requestId',
      header: 'REQUEST ID',
      valuePath: 'request_id'
    }] : []),
  ...(columns.includes(SYSTEM_TYPE) ?
    [{
      id: 'systemType',
      header: 'SYSTEM TYPE',
      valuePath: 'system_type',
      defaultValue: 'Residential'
    }] : []),
  ...(columns.includes(OWNER_NAME) ?
    [{
      id: 'ownerName',
      header: 'OWNER NAME',
      valuePath: 'owner.name'
    }] : []),
  ...(columns.includes(OWNER_EMAIL) ?
    [{
      id: 'ownerEmail',
      header: 'OWNER EMAIL',
      valuePath: 'owner.email'
    }] : []),
  ...(columns.includes(CITY) ?
    [{
      id: 'city',
      header: 'CITY',
      valuePath: 'owner.city'
    }] : []),
  ...(columns.includes(STATE) ?
    [{
      id: 'state',
      header: 'STATE',
      valuePath: 'owner.state'
    }] : []),
  ...(columns.includes(COUNTRY) ?
    [{
      id: 'country',
      header: 'COUNTRY',
      valuePath: 'owner.country'
    }] : []),
  ...(columns.includes(ZIP) ?
    [{
      id: 'zip',
      header: 'ZIP',
      valuePath: 'owner.zip'
    }] : []),
  ...(columns.includes(MICRO_PART_NUMBER) ?
    [{
      id: 'microPartNumber',
      header: 'MICRO PART NUMBER',
      deviceId: device_types.microInvertor
    }] : []),
  ...(columns.includes(MICRO_COUNT) ?
    [{
      id: 'microCount',
      header: 'MICRO COUNT',
      deviceId: device_types.microInvertor
    }] : []),
  ...(columns.includes(ENCHARGE_PART_NUMBER) ?
    [{
      id: 'enchargePartNumber',
      header: 'ENCHARGE PART NUMBER',
      deviceId: device_types.encharge
    }] : []),
  ...(columns.includes(ENCHARGE_COUNT) ?
    [{
      id: 'enchargeCount',
      header: 'ENCHARGE COUNT',
      deviceId: device_types.encharge
    }] : []),
  ...(columns.includes(IQ_RELAY_PART_NUMBER) ?
    [{
      id: 'iqRelayPartNumber',
      header: 'IQ RELAY PART NUMBER',
      deviceId: device_types.iqRelay
    }] : []),
  ...(columns.includes(IQ_RELAY_COUNT) ?
    [{
      id: 'iqRelayCount',
      header: 'IQ RELAY COUNT',
      deviceId: device_types.iqRelay
    }] : []),
  ...(columns.includes(ACB_PART_NUMBER) ?
    [{
      id: 'acbPartNumber',
      header: 'ACB PART NUMBER',
      deviceId: device_types.acb
    }] : []),
  ...(columns.includes(ACB_COUNT) ?
    [{
      id: 'acbCount',
      header: 'ACB COUNT',
      deviceId: device_types.acb
    }] : []),
  ...(columns.includes(ENVOY_PART_NUMBER) ?
    [{
      id: 'envoyPartNumber',
      header: 'ENVOY PART NUMBER',
      valuePath: 'part_num'
    }] : []),
  ...(columns.includes(ENVOY_COUNT) ?
    [{
      id: 'envoyCount',
      header: 'ENVOY COUNT',
      defaultValue: '1'
    }] : []),
  ...(columns.includes(ENPOWER_PART_NUMBER) ?
    [{
      id: 'enpowerPartNumber',
      header: 'ENPOWER PART NUMBER',
      deviceId: device_types.enpower
    }] : []),
  ...(columns.includes(ENPOWER_COUNT) ?
    [{
      id: 'enpowerCount',
      header: 'ENPOWER COUNT',
      deviceId: device_types.enpower
    }] : []),
])

const ALL_COLUMNS = [SYSTEM_ID, SYSTEM_NAME, REQUEST_ID, SYSTEM_TYPE, OWNER_NAME, OWNER_EMAIL, CITY, STATE, COUNTRY, ZIP, MICRO_PART_NUMBER, MICRO_COUNT, ENCHARGE_PART_NUMBER, ENCHARGE_COUNT, IQ_RELAY_PART_NUMBER, IQ_RELAY_COUNT, ACB_PART_NUMBER, ACB_COUNT, ENVOY_PART_NUMBER, ENVOY_COUNT, ENPOWER_PART_NUMBER, ENPOWER_COUNT]
const DEFAULT_COLUMNS = [SYSTEM_ID, SYSTEM_NAME, REQUEST_ID, SYSTEM_TYPE, OWNER_NAME, OWNER_EMAIL]

export {
  tableHeadings,
  ALL_COLUMNS,
  DEFAULT_COLUMNS
}