import React from 'react'

function CircleCross() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path fill="#f15b5a" d="M18.656,7.343a8,8,0,1,0,0,11.314A8,8,0,0,0,18.656,7.343Zm-3.526,9L13,14.214l-2.131,2.131L9.655,15.13,11.785,13l-2.13-2.13,1.214-1.214L13,11.786l2.13-2.13,1.214,1.213L14.213,13l2.131,2.131Z" transform="translate(-4.999 -5)" />
    </svg>
  )
}

export default CircleCross