import getConfig from '../config/env'

class Urls {
  constructor(config) {
    this.config = config
  }

  getApiUrl(url) {
    return `${this.config.api}/e2/${url}`
  }
}

const url = new Urls(getConfig(process.env.NODE_ENV))

export default url
