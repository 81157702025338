import { Breadcrumbs, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import styles from './style.module.css'

const Breadcrumb = (props) => {

  let path = window.location.pathname
  path = path.split('/').filter(st => st)

  let breadcrumb = []
  for (let i = 0; i < path.length - 1; i++) {
    breadcrumb.push(
      <RouterLink className={styles.linkstyle} color="inherit" to={`/${path[i]}`} >
        {path[i].replace(/(\w)(\w*)/g, (g0, g1, g2) => { return g1.toUpperCase() + g2.toLowerCase() })}
      </RouterLink >
    )
  }

  return (
    <div className={`${styles.root} ${styles.m4}`}>
      {props && props.value ?
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {props.value.map((val, index) => {
            if (index + 1 === props.value.length) {
              return <Typography key={index} color="primary">{Object.keys(val)[0]}</Typography>
            } else {
              for (const key in val) {
                return <RouterLink key={index} className={styles.linkstyle} color="inherit" to={{ pathname: `${val[key]}`, state: val['state'] ? { ...val['state'] } : undefined, ...(val['search'] ? { search: `${val['search']}` } : {}) }} >{key}</RouterLink>
              }
            }
            return null
          })}
        </Breadcrumbs>
        :
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <RouterLink className={styles.linkstyle} to="/" >
            Home
          </RouterLink>
          {breadcrumb}
          <Typography color="primary">{path[path.length - 1].replace(/(\w)(\w*)/g, (g0, g1, g2) => { return g1.toUpperCase() + g2.toLowerCase() })}</Typography>
        </Breadcrumbs>
      }
    </div >
  )
}

export default Breadcrumb