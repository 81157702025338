import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { Box } from '@mui/material'
import history from './history'
import DefaultLayout from '../layouts/default'
import {
  Dashboard,
  VerfiyOtp,
  OtpForm,
  Signin,
  SiteForm
} from "../layouts"
import AppRoutes from './AppRoutes'
import Header from '../containers/Header'

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <>
          <Header isSignedInUser />
          <Box flexGrow="1">
            <Component {...props} />
          </Box>
        </>
      ) : (
        <Redirect
          to={{ pathname: '/signin', state: { from: props.location } }}
        />
      )
    }
  />
)

class Routes extends Component {

  render() {
    const { authUser, location, match } = this.props
    if (location.pathname === '/') {
      if (authUser === null || _.isEmpty(authUser)) {
        return <Redirect to="/signin" />
      } else {
        return <Redirect to="/dashboard" />
      }
    }

    return (
      <div className="App-container">
        <Router history={history}>
          <Switch>
            <DefaultLayout exact path="/" component={Signin} />
            <DefaultLayout exact path="/signin" component={Signin} />
            <DefaultLayout exact path="/verify-otp" component={OtpForm} />
            <RestrictedRoute path={`${match.url}`} authUser={authUser} component={AppRoutes} />
            <Redirect to="/signin" />
          </Switch>
        </Router>
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth
  return { authUser }
}

export default connect(mapStateToProps, {})(Routes)
