import { Box } from '@mui/material'
import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'
import Header from '../containers/Header'

const Layout = ({ children, isMobileView, loading, matchProps, ...rest }) => {
  return <Fragment>{children}</Fragment>
}

class DefaultLayout extends React.Component {

  render() {
    const { component: Component, loading, isSignedInUser, ...rest } = this.props
    return (
      <Route
        {...rest}
        render={matchProps => (
          <Layout loading={loading} matchProps={matchProps}>
            <Header />
            <Box flexGrow="1">
              <Component {...matchProps} />
            </Box>
          </Layout>
        )}
      />
    )
  }
}

export default DefaultLayout
