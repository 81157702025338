import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from '../reducers'
import rootSaga from '../sagas'
import createSagaMiddleware from 'redux-saga'

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]
let middleware = applyMiddleware(...middlewares)

if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
  middleware = compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__())
}

export default function configureStore() {
  const store = createStore(rootReducer, middleware)
  store.runSaga = sagaMiddleware.run(rootSaga)
  return store
}
