import "./verify_otp.css"

import React from "react";
import Navbar from "../../components/navbar/navbar";
import Heading from "../../components/envoy_twin_heading/heading";
import ErrorMessage from "../../components/error_message/error_message";
import LoginButton from "../../components/button/button";

class VerfiyOtp extends React.Component {
    constructor(props) {
        super(props);
        this.state = { otp: '1234', loading: false, error_message: ''};

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }
    handleChange(event) {
        this.setState({otp: event.target.value});
    }

    handleClick = async () => {
        this.setState({loading: true});
        let user_email = "";
        try {
            user_email = localStorage.getItem("envoy_twin_email_id");
            if (user_email == null) {
                this.setState({loading: false});
                this.props.navigator("/");
            }
        }
        catch (e) {
            this.setState({loading: false});
            this.props.navigator("/");
        }
        const payload = {email_id: user_email, otp: this.state.otp};
        const response = await fetch(process.env.REACT_APP_ENVOY_TWIN_END_POINT + "/e2/login/verify_otp",
            {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        const myJson = await response.json();
        if (Math.floor(response.status / 100) === 2) {
            this.setState({loading: false});
            localStorage.setItem('envoy_twin_auth_token', myJson.jwt_access_token);
            return this.props.navigator("/home");
        }
        else if (Math.floor(response.status / 100) === 4) {
            this.setState({error_message: "something went wrong please retry"});
        }
        else if (Math.floor(response.status / 100) === 5) {
            this.setState({error_message: myJson.message});
        }
        else {
            this.setState({error_message: "something went wrong please retry"});
        }
        this.setState({loading: false});
    }
    render() {
        return <div className="container-center-horizontal">
            <div className="verify-otp screen">
                <Navbar loginenabled={false}/>
                <Heading heading="Envoy-twin"/>
                <div className="overlap-group">
                    <div className="otp-verify enphasevisuelt-bold-black-24px">
                        <span className="enphasevisuelt-bold-black-24px">OTP Verification</span>
                    </div>
                    <div className="card">
                        <input className="otp-text valign-text-bottom enphasevisuelt-regular-normal-cod-gray-18px"
                               type="text" placeholder="enter otp"
                               onChange={this.handleChange}></input>
                    </div>
                    {this.state.error_message !== '' ? <ErrorMessage text={this.state.error_message}/> : null}
                    <LoginButton text="Submit" handleClick={this.handleClick} loading={this.state.loading}/>
                </div>
            </div>
        </div>
    }
}

export default VerfiyOtp;
