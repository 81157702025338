const device_types = {
    envoy: 1,
    microInvertor: 2,
    encharge: 13,
    enpower: 17,
    iqRelay: 12,
    acb: 11
}

export default device_types;
