const OWNER_DETAILS = 'owner_details'
const PARTS_DETAILS = 'parts_details'
const ToggleOptions = (ownerFormFilled) => ([
  {
    id: OWNER_DETAILS,
    label: 'Owner Details',
    disabled: false,
  },
  {
    id: PARTS_DETAILS,
    label: 'Parts Details',
    disabled: !ownerFormFilled
  },
])

const microMarks = [{
  value: 10,
  label: '10'
}, {
  value: 20,
  label: '20'
}, {
  value: 30,
  label: '30'
}, {
  value: 40,
  label: '40'
}, {
  value: 50,
  label: '50'
}]

const enchargeMarks = [{
  value: 0,
  label: '0'
}, {
  value: 1,
  label: '1'
}, {
  value: 2,
  label: '2'
}, {
  value: 3,
  label: '3'
}, {
  value: 4,
  label: '4'
}, {
  value: 5,
  label: '5'
}]

const relayMarks = [{
  value: 0,
  label: '0'
}, {
  value: 1,
  label: '1'
}]

const acbMarks = [{
  value: 0,
  label: '0'
}, {
  value: 1,
  label: '1'
}, {
  value: 2,
  label: '2'
}, {
  value: 3,
  label: '3'
}, {
  value: 4,
  label: '4'
}, {
  value: 5,
  label: '5'
}]
const envoyMarks = [{
  value: 0,
  label: '0'
}, {
  value: 1,
  label: '1'
}]
const enpowerMarks = [{
  value: 0,
  label: '0'
}, {
  value: 1,
  label: '1'
}]

const sitesMarks = [{
  value: 10,
  label: '10'
}, {
  value: 20,
  label: '20'
}, {
  value: 30,
  label: '30'
}, {
  value: 40,
  label: '40'
}, {
  value: 50,
  label: '50'
}]

export {
  OWNER_DETAILS,
  PARTS_DETAILS,
  ToggleOptions,
  microMarks,
  enchargeMarks,
  relayMarks,
  acbMarks,
  envoyMarks,
  enpowerMarks,
  sitesMarks
}