import "./button.css"

function LoginButton(props) {
    const { text, handleClick, loading } = props;
    return <button className="login_button" onClick={handleClick} disabled={loading}>
        <div className="continue enphasevisuelt-medium-white-16px">
            <span className="enphasevisuelt-medium-white-16px">
                {text}
            </span>
        </div>
    </button>
}

export default LoginButton;
