import { put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import Urls from '../../utils/urlStrings'
import {
  SIGNIN_USER,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAILED,
  SIGNOUT_USER,
  USER_SIGNOUT_SUCCESS,
  USER_SIGNOUT_FAILED,
  GET_CURRENT_USER,
  GET_CURRENT_USER_SUCCESS,
  VERIFY_OTP,
  OTP_VERIFICATION_SUCCESS,
  OTP_VERIFICATION_FAILED,
} from '../../constants/actionTypes'
import service from '../../utils/service'
import {
  setToken,
  getSessionId,
  setEmailId,
  getEmailId,
} from '../../utils/helper'
import _ from 'lodash'
import { removeLocalStorage } from '../../utils/localStorage'
import { SOMETHING_WENT_WRONG } from '../../constants/messagesConstant'

const signInWithDetailsRequest = async (payload) =>
  await service.post(Urls.getApiUrl(`login/send_otp`), { ...payload })
    .then((authUser) => authUser)
    .catch((error) => error)

const verifyOTPRequest = async (payload) =>
  await service.post(Urls.getApiUrl(`login/verify_otp`), { ...payload })
    .then((authUser) => authUser)
    .catch((error) => error)

const signOutCurrentUserRequest = async (payload) =>
  await service.get(Urls.getApiUrl(`login/logout`))
    .then(logOutData => logOutData)
    .catch(error => error)

const getCurrentUserRequest = async () =>
  await service.get(Urls.getApiUrl(`fetch_user_details`))
    .then((currentUserData) => currentUserData)
    .catch((error) => error)

function* signInUserWithDetails({ payload }) {
  try {
    const response = yield call(signInWithDetailsRequest, {
      email_id: payload.emailId,
    })
    const { status, data } = response
    if (status === 200 || status === 538) {
      setEmailId(payload.emailId)
      yield put({ type: USER_SIGNIN_SUCCESS, payload: data })
    } else {
      yield put({ type: USER_SIGNIN_FAILED, message: _.get(data, 'message', SOMETHING_WENT_WRONG) })
    }
  } catch (error) {
    yield put({ type: USER_SIGNIN_FAILED })
  }
}

function* verifyOtpForEmail({ payload }) {
  try {
    const response = yield call(verifyOTPRequest, {
      email_id: getEmailId(),
      otp: payload.otp,
    })
    const { status, data } = response
    if (status === 200) {
      setToken(data.jwt_access_token)
      localStorage.setItem('envoy_twin_auth_token', data.jwt_access_token)
      yield put({ type: OTP_VERIFICATION_SUCCESS, payload: data })
    } else {
      yield put({ type: OTP_VERIFICATION_FAILED, message: _.get(data, 'message', SOMETHING_WENT_WRONG) })
    }
  } catch (error) {
    yield put({ type: OTP_VERIFICATION_FAILED })
  }
}

function* signOutCurrentUser() {
  try {
    const response = yield call(signOutCurrentUserRequest, {})
    const { status } = response
    if (status === 200) {
      removeLocalStorage('auth')
      removeLocalStorage('email_id')
      removeLocalStorage('envoy_twin_auth_token')
      yield put({ type: USER_SIGNOUT_SUCCESS })
    } else {
      yield put({ type: USER_SIGNOUT_FAILED })
    }
  } catch (error) {
    yield put({ type: USER_SIGNOUT_FAILED })
  }
}

function* getCurrentUserWithToken({ payload }) {
  try {
    const response = yield call(getCurrentUserRequest, payload)
    const { status, data } = response
    if (status === 200) {
      yield put({ type: GET_CURRENT_USER_SUCCESS, payload: data })
    } else {
    }
  } catch (error) {
    console.debug(error)
  }
}

export function* signInWithDetails() {
  yield takeEvery(SIGNIN_USER, signInUserWithDetails)
}

export function* verifyOTP() {
  yield takeEvery(VERIFY_OTP, verifyOtpForEmail)
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOutCurrentUser)
}

export function* getCurrentUser() {
  yield takeLatest(GET_CURRENT_USER, getCurrentUserWithToken)
}
