import {
  SIGNIN_USER,
  SIGNOUT_USER,
  GET_CURRENT_USER,
  CLEAR_MESSAGE,
  VERIFY_OTP
} from "../../constants/actionTypes"

export function userSignIn(user) {
  return { type: SIGNIN_USER, payload: user }
}

export function verifyOtp(otp) {
  return { type: VERIFY_OTP, payload: otp }
}

export function userSignOut() {
  return { type: SIGNOUT_USER }
}

export function getCurrentUser(token) {
  return { type: GET_CURRENT_USER, payload: token }
}

export function clearMessage() {
  return { type: CLEAR_MESSAGE }
}