import "./navbar.css"
import enphase_logo from "../../images/enphase-logo.png"
import line_image from "../../images/line-image.png"

function NavBar(props) {
    const { loginenabled, email_id, triggerlogout } = props;

    return (
        <div className="nav-bar">
            <img className="navbar-logo" src={enphase_logo} alt="Logo" />
            {loginenabled ?
                <div className="login_enabled">
                    <span className="enphasevisuelt-medium-black-16px">{email_id}</span>
                    <img className="line-sep" src={line_image} alt="Logo" />
                    <span className="logout enphasevisuelt-bold-black-12px" onClick={triggerlogout}>Logout</span>
                </div> : ""}
        </div>
    )
}

export default NavBar;
