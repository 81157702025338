import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import {
  Link,
  Grid,
  Box,
  Typography,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Popover,
} from '@mui/material'
import Pagination from '@mui/material/Pagination'
import styles from './style.module.css'
import { ArrowUpward, ArrowDownward, InfoOutlined, FilterAltOutlined } from '@mui/icons-material'
import { SORTORDER } from '../../utils/tableConstants'
import DraftsIcon from '@mui/icons-material/Drafts'

export default function TableOutline(props) {
  const { tableData, tableHeadings, page, rowsPerPage, totalElement, loading = false } = props
  const [startIndex, setStartIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(0)
  const [totalPageCount, setTotalPageCount] = useState(0)
  const tableRef = useRef()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [infoAnchorEl, setInfoAnchorEl] = useState(null)
  const [infoId, setInfoId] = useState()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleInfoClick = (event, id) => {
    setInfoAnchorEl(event.currentTarget)
    setInfoId(id)
  }

  const handleInfoClose = () => {
    setInfoAnchorEl(null)
    setInfoId(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'dropsown-popover' : undefined

  const hasLink = (id) => {
    return tableHeadings.some((obj) => {
      return obj.id === id && obj.isLink
    })
  }

  useEffect(() => {
    const startPoint = (page - 1) * rowsPerPage + 1
    let endPoint = startPoint + rowsPerPage - 1
    endPoint = endPoint > totalElement ? totalElement : endPoint
    const pageCount = Math.ceil(totalElement / rowsPerPage)
    setStartIndex(totalElement === 0 ? 0 : startPoint)
    setEndIndex(endPoint)
    setTotalPageCount(pageCount)
  }, [rowsPerPage, totalElement, page])

  return (
    <Grid className={styles.spacing} container>
      <TableContainer className={styles.table} component={Paper}>
        <Table stickyHeader size={props.small ? 'small' : 'medium'} ref={tableRef}>
          <TableHead>
            <TableRow>
              {tableHeadings.map((obj, index) => {
                return (
                  <TableCell key={index} className={`${styles.tableHeader} ${obj.className ? obj.className : ''}`}>
                    {obj.isDropdown ?
                      <>
                        <Button
                          className={`${styles.headerWrapper} ${styles.tableHeading}`}
                          aria-describedby={id}
                          variant="contained"
                          disableElevation
                          disableRipple
                          disableFocusRipple
                          style={{ backgroundColor: 'transparent' }}
                          onClick={handleClick}
                          endIcon={<FilterAltOutlined />}
                        >
                          {obj.header}
                        </Button>
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          PaperProps={{
                            className: styles.popoverPaper,
                          }}
                        >
                          {obj.dropDownValues.map((value, index) => (
                            <Box className={`${styles.dropDownValues} ${value === props.selectedStatus ? styles.active : ''}`} key={index} p={1.5} onClick={() => { props.handleDropdownChange(value); handleClose() }}>{value}</Box>
                          ))}
                        </Popover>
                      </> :
                      obj.isPopover ?
                        <>
                          <Button
                            className={`${styles.headerWrapper} ${styles.tableHeading}`}
                            aria-describedby={id}
                            variant="contained"
                            disableElevation
                            disableRipple
                            disableFocusRipple
                            style={{ backgroundColor: 'transparent', cursor: 'default' }}
                            endIcon={<InfoOutlined className={styles.infoIcon} onClick={(e) => handleInfoClick(e, obj.id)} />}
                          >
                            {obj.header}
                          </Button>
                          <Popover
                            id={infoId}
                            open={infoId === obj.id}
                            anchorEl={infoAnchorEl}
                            onClose={handleInfoClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            PaperProps={{
                              className: styles.infoPopoverPaper,
                            }}
                          >
                            <Box className={styles.infoPopover} p={1.5}>
                              {obj.popoverContent}
                            </Box>
                          </Popover>
                        </> : <IconButton
                          size="small"
                          className={`${styles.headerWrapper} ${obj.isSortable ? '' : styles.noPointer} ${styles.tableHeading}`}
                          disableRipple={true}
                          onClick={() => obj.isSortable && props.handleSortData(obj)}
                        >
                          {_.get(obj, 'header', '')}
                          {obj.isSortable &&
                            (obj.sortOrder === SORTORDER.ASC ? (
                              <ArrowUpward
                                fontSize="small"
                                className={styles.sortIcon}
                              />
                            ) : (
                              <ArrowDownward
                                fontSize="small"
                                className={styles.sortIcon}
                              />
                            ))}
                        </IconButton>
                    }
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading || tableData.length > 0 ?
              tableData.map((row, index) => (
                <TableRow key={index}>
                  {row.map((obj, index) => {
                    return (
                      <TableCell key={index} className={obj.className}>
                        {hasLink(obj.id) ? (
                          (
                            <Link
                              component="button"
                              variant="body2"
                              onClick={() => obj.handleClick(obj)}
                              style={{ color: '#3B86FF', textAlign: 'left' }}
                            >
                              {_.get(obj, 'value', '')}
                            </Link>
                          )
                        ) : (
                          <Typography>{_.get(obj, 'value', '')}</Typography>
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )) :
              <TableRow>
                <TableCell colSpan={tableHeadings.length}>
                  <Box display="flex" justifyContent="center" alignItems="center" minHeight="150px" flexDirection="column">
                    <DraftsIcon fontSize="large" color="disabled" />
                    No Data Found
                  </Box>
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      {props.pagination && (
        <Box className={styles.paginationWrapper}>
          <Box className={styles.paginationSelectWrapper}>
            <Typography>Rows per page</Typography>
            <Select
              value={rowsPerPage}
              variant="standard"
              onChange={(event) => {
                tableRef.current && tableRef.current.scrollIntoView()
                props.handleChangeRowsPerPage(event.target.value)
              }}
              classes={{ root: styles.selectRoot }}
              className={styles.selectWrapper}
            >
              {props.rowsPerPageOptions.map((value) => {
                return <MenuItem key={value} value={value}>{value}</MenuItem>
              })}
            </Select>
            <Typography>{`${startIndex}-${endIndex} of ${totalElement}`}</Typography>
          </Box>
          <Pagination
            page={page}
            count={totalPageCount}
            shape="rounded"
            onChange={(event, newPage) => {
              page !== newPage && props.handleChangePage(newPage)
              page !== newPage && tableRef.current && tableRef.current.scrollIntoView()
            }}
          />
        </Box>
      )}
    </Grid>
  )
}

TableOutline.defaultProps = {
  tableData: [],
  tableHeadings: [],
  pagination: false,
  rowsPerPage: 10,
  page: 1,
  totalElement: 0,
  rowsPerPageOptions: [10, 25, 100],
  handleChangePage: () => { },
  handleChangeRowsPerPage: () => { },
  handleSortData: () => { },
}
