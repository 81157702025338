import React from 'react'
import styles from './style.module.css'
import CircularProgress from '@mui/material/CircularProgress'

const Loader = ({ loading, style }) => {
  if (loading)
    return (
      <div style={style} className={styles.root}>
        <CircularProgress />
      </div>
    )
  return null
}

export default Loader
