import "./home_page.css";

import Navbar from "../../components/navbar/navbar";
import Heading from "../../components/envoy_twin_heading/heading";
import LoginButton from "../../components/button/button";
import ErrorMessage from "../../components/error_message/error_message";
import React from "react";

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {email_id: "Enter your valid Enphase email-id", loading: false, error_message: ''};

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }
    handleChange(event) {
        this.setState({email_id: event.target.value});
    }

    handleClick = async () => {
        this.setState({loading: true});
        const payload = {email_id: this.state.email_id};
        const response = await fetch(process.env.REACT_APP_ENVOY_TWIN_END_POINT + "/e2/login/send_otp",
            {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        const myJson = await response.json();

        // 538 status is when the otp already exists
        if (Math.floor(response.status / 100) === 2 || response.status ===  538) {
            console.log("i am reaching");
            this.setState({loading: false});
            localStorage.setItem('envoy_twin_email_id', this.state.email_id);
            return this.props.navigator("/verify-otp");
        }
        else if (Math.floor(response.status / 100) === 4) {
            this.setState({error_message: "something went wrong please retry"});
        }
        else if (Math.floor(response.status / 100) === 5) {
            this.setState({error_message: myJson.message});
        }
        else {
            this.setState({error_message: "something went wrong please retry"});
        }
        this.setState({loading: false});
    }

    render() {
        return <div className="container-center-horizontal">
            <div className="home screen">
                <Navbar loginenabled={false}/>
                <Heading heading="Envoy-twin"/>
                <div className="overlap-group">
                    <div className="login enphasevisuelt-bold-black-24px">
                        <span className="enphasevisuelt-bold-black-24px">Login</span>
                    </div>
                    <div className="card">
                        <input className="mail-id-button valign-text-bottom enphasevisuelt-regular-normal-cod-gray-18px"
                               type="text" placeholder="enter your valid enphase email-id"
                               onChange={this.handleChange}></input>
                    </div>
                    {this.state.error_message !== '' ? <ErrorMessage text={this.state.error_message}/> : null}
                    <LoginButton text="Continue" handleClick={this.handleClick} loading={this.state.loading}/>
                </div>
            </div>
        </div>
    }
}

export default HomePage;
