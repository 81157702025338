import { useState } from "react"
import { connect } from "react-redux"
import { Box, Divider, Hidden, Popover, Typography } from "@mui/material"
import _ from 'lodash'
import LogoImage from '../../assets/Icons/Logo.png'
import UserIcon from '../../assets/Icons/user.svg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import styles from './style.module.css'
import { userSignOut } from '../../appRedux/actions/Auth'

const Header = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { currentUser } = props

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Box className={styles.container}>
      <Box className={styles.headerContent} display="flex" justifyContent="space-between" alignItems="center" px={2}>
        <Box display="flex" alignItems="center">
          <img src={LogoImage} className={styles.logoImage} alt='logo' />
          <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>{"Envoy-Twin"}</Typography>
        </Box>
        {props.isSignedInUser &&
          <>
            <Box className={styles.pointer} display="flex" alignItems="center" onClick={handleClick}>
              <img className={styles.avatar} src={UserIcon} alt="" />
              <Hidden smDown>
                <Typography className={styles.avatarText}>{`Hi ${_.get(props, 'currentUser.user_email', '')}`}</Typography>
              </Hidden>
              <ExpandMoreIcon />
            </Box>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              PaperProps={{
                className: styles.headerPaper,
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Hidden smUp>
                <Typography className={styles.popoverText}>{`Hi ${_.get(props, 'currentUser.user_email', '')}`}</Typography>
                <Divider />
              </Hidden>
              <Typography className={styles.typography} onClick={() => { handleClose(); props.userSignOut() }}>Log Out</Typography>
            </Popover>
          </>
        }
      </Box>
    </Box>
  )
}

const mapStateToProps = ({ auth }) => {
  const { authUser, currentUser } = auth
  return { authUser, currentUser }
}

const mapDispatchToProps = dispatch => ({
  userSignOut: () => dispatch(userSignOut()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)