import { Box, Typography } from '@mui/material'
import styles from './style.module.css'
const Toggle = (props) => {
  const { list, onChange } = props

  const renderListView = (obj) => {
    return (
      <Box className={styles.wrapper} key={obj.id}>
        <Box
          className={`${styles.toggleLabel} ${obj.id === props.selected ? styles.selected : ''} ${obj.disabled ? styles.disabled : ''}`}
          onClick={() => onChange(obj.id)}
        >
          {obj.label}
        </Box>
        <Typography
          className={
            obj.id === props.selected
              ? styles.highlight
              : styles.highlightWrapper
          }
        ></Typography>
      </Box>
    )
  }

  return <Box className={styles.root}>{list.map(renderListView)}</Box>
}

Toggle.defaultProps = {
  list: [],
  onChange: () => { },
}

export default Toggle
