import Icon from './icons'
import _ from 'lodash'

const renderIcon = (errors, value, touched) => {
  if (touched) {
    return touched ? _.isUndefined(errors) ? <Icon src="circleTick" /> : <Icon src="circleCross" /> : <></>
  }
  return value ? _.isUndefined(errors) ? <Icon src="circleTick" /> : <Icon src="circleCross" /> : <></>
}

const getErrors = (errors, value, touched) => {
  return touched ? touched && Boolean(errors) : value && Boolean(errors)
}

const getHelperText = (errors, value, touched) => {
  return touched ? touched && errors : value && errors
}

export {
  getErrors,
  getHelperText,
  renderIcon
}