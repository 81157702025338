import { getLocalStorage, setLocalStorage, getSessionStorage, setSessionStorage } from "./localStorage"

export const getToken = () => (
  getSessionStorage('auth') || getLocalStorage('auth')
)

export const setToken = value => setLocalStorage('auth', value)

export const setSessionId = value => setLocalStorage('session_id', value)

export const getSessionId = () => getLocalStorage('session_id')

export const setEmailId = value => setLocalStorage('email_id', value)

export const getEmailId = () => getLocalStorage('email_id')

export const getSessionToken = () => getSessionStorage('auth')

export const setSessionToken = value => setSessionStorage('auth', value)

export const getSessionAccountId = () => getSessionStorage('account_id')

export const setSessionAccountId = value => setSessionStorage('account_id', value)