import { getToken } from '../../utils/helper'
import * as CONSTANTS from '../../constants/actionTypes'
import * as MESSAGES from '../../constants/messagesConstant'
import * as MESSAGETYPES from '../../utils/messageTypes'

let initialState = {
  authUser: getToken(),
  currentUser: '',
  message: '',
  messageType: '',
  verifiedEmail: false,
  loading: false,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.SIGNIN_USER:
      return { ...state, loading: true }
    case CONSTANTS.USER_SIGNIN_SUCCESS:
      return {
        ...state,
        message: MESSAGES.SIGNIN_SUCCESS,
        messageType: MESSAGETYPES.SUCCESS,
        verifiedEmail: true,
        loading: false
      }
    case CONSTANTS.USER_SIGNIN_FAILED:
      return {
        ...state,
        message: action.message,
        messageType: MESSAGETYPES.ERROR,
        loading: false
      }

    case CONSTANTS.VERIFY_OTP:
      return { ...state, loading: true }

    case CONSTANTS.OTP_VERIFICATION_SUCCESS:
      return {
        ...state,
        authUser: action.payload.jwt_access_token,
        loading: false,
      }

    case CONSTANTS.OTP_VERIFICATION_FAILED:
      return {
        ...state,
        message: action.message,
        messageType: MESSAGETYPES.ERROR,
        loading: false
      }
    case CONSTANTS.USER_SIGNOUT_SUCCESS:
      return { ...state, authUser: '', verifiedEmail: false, currentUser: '', message: '' }
    case CONSTANTS.USER_SIGNOUT_FAILED:
      return { ...state, authUser: '', verifiedEmail: false, message: '' }
    case CONSTANTS.GET_CURRENT_USER_SUCCESS:
      return { ...state, currentUser: action.payload }
    case CONSTANTS.CLEAR_MESSAGE:
      return { ...state, message: '', messageType: '' }
    default:
      return state
  }
}

export default authReducer
