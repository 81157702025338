export const SIGNIN_USER = 'SIGNIN_USER'
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS'
export const USER_SIGNIN_FAILED = 'USER_SIGNIN_FAILED'

export const VERIFY_OTP = 'VERIFY_OTP'
export const OTP_VERIFICATION_SUCCESS = 'OTP_VERIFICATION_SUCCESS'
export const OTP_VERIFICATION_FAILED = 'OTP_VERIFICATION_FAILED'


export const SIGNOUT_USER = 'SIGNOUT_USER'
export const USER_SIGNOUT_SUCCESS = 'USER_SIGNOUT_SUCCESS'
export const USER_SIGNOUT_FAILED = 'USER_SIGNOUT_FAILED'

export const GET_CURRENT_USER = 'GET_CURRENT_USER'
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS'

export const CLEAR_MESSAGE = 'CLEAR_MESSAGE'
