import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route } from 'react-router-dom'
import Routes from '../../routes/index'
import { StyledEngineProvider, ThemeProvider } from '@mui/material'
import theme from '../../theme'
import configureStore from '../../appRedux/store'
import './App.css'

export const store = configureStore()

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <Provider store={store}>
          <BrowserRouter>
            <Route path="/" component={Routes} />
          </BrowserRouter>
        </Provider>
      </StyledEngineProvider>
    </ThemeProvider>
  )
}

export default App
