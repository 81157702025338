import "./error_message.css"

function ErrorMessage(props) {
    const { text } = props;
    return (
        <p className="error_message enphasevisuelt-regular-normal-rusty-red-14px">
            <span className="enphasevisuelt-regular-normal-rusty-red-14px">
                {text}
            </span>
        </p>
    );
}

export default ErrorMessage;
