import _ from 'lodash'
import service from '../../utils/service'
import Urls from '../../utils/urlStrings'
import device_types from '../../utils/constants'
import moment from 'moment'

export class SitesServices {
  async createSites(data) {
    let payload = {
      envoy: {
        device_type_id: device_types.envoy,
        product: "IQ-Envoy",
        part_num: !_.isEmpty(data.envoyNumber) ? data.envoyNumber : "800-00655-r09",
      },
      owner: {
        name: data.ownerName,
        email: data.ownerEmail,
        country: data.country,
        state: data.state,
        city: data.city,
        zip: data.zip
      },
      devices: [
        ...(data.microCount ? [{
          device_type_id: device_types.microInvertor,
          product: "IQ-Invertor",
          part_num: !_.isEmpty(data.microNumber) ? data.microNumber : "800-01131-r02",
          n_devices: data.microCount
        }] : []),
        ...(data.enchargeCount ? [{
          device_type_id: device_types.encharge,
          product: "IQ-Battery",
          part_num: !_.isEmpty(data.enchargeNumber) ? data.enchargeNumber : "830-00703-r84",
          n_devices: data.enchargeCount
        }] : []),
        ...(data.relayCount ? [{
          device_type_id: device_types.iqRelay,
          product: "IQ-Relay",
          part_num: !_.isEmpty(data.relayNumber) ? data.relayNumber : "800-00597-r02",
          n_devices: data.relayCount
        }] : []),
        ...(data.acbCount ? [{
          device_type_id: device_types.acb,
          product: "ACB",
          part_num: !_.isEmpty(data.acbNumber) ? data.acbNumber : "800-00930-r03",
          n_devices: data.acbCount
        }] : []),
        ...(data.enpowerCount ? [{
          device_type_id: device_types.enpower,
          product: "IQ-System-Controller",
          part_num: !_.isEmpty(data.enpowerNumber) ? data.enpowerNumber : "860-00276-r32",
          n_devices: data.enpowerCount
        }] : [])
      ],
      n_sites: data.sitesCount,
      is_immediate: false,
      end_time: moment().add(1, 'month').endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      from_seed: true,
      battery_mode: "SelfConsumption",
      timezone: "America/Los_Angeles",
      reporting_time: 300
    }
    try {
      const response = await service.post(Urls.getApiUrl(`job`), { ...payload })
      const { data } = response
      return data.data
    } catch (e) {
      throw e
    }
  }
  async getUserDetails(page_no, page_size) {
    const params = { page_no, page_size }
    try {
      const response = await service.get(Urls.getApiUrl(`fetch_user_details`), { params: params })
      const { data } = response
      return data
    } catch (e) {
      throw e
    }
  }
}

export const sitesServices = new SitesServices()
