import { useEffect, useState } from "react"
import { Box, Typography } from "@mui/material"
import { AppButton, Breadcrumb, Loader, TableOutline } from "../../components"
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined'
import styles from './style.module.css'
import { DEFAULT_COLUMNS, tableHeadings } from "./constants"
import { useHistory } from 'react-router'
import { sitesServices } from "../../services/SitesServices"
import _ from "lodash"
import ColumnSelector from "./ColumnSelector"

const Dashboard = () => {
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [columns, setColumns] = useState(DEFAULT_COLUMNS)
  const [anchorEl, setAnchorEl] = useState(null)
  const [headings, setHeadings] = useState(tableHeadings(columns))
  const [userData, setUserData] = useState([])

  const history = useHistory()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'column-selector' : undefined

  const handlePageChange = (newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(value)
    setPage(1)
  }

  const getUserDetails = () => {
    setLoading(true)
    sitesServices.getUserDetails(page - 1, rowsPerPage)
      .then(userData => {
        setUserData(userData.request_summary)
        setRequestTable(userData.request_summary)
        setTotalCount(_.get(userData, 'pagination_info.total_count', 0))
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const getFieldValue = (obj, heading) => {
    if (heading.deviceId) {
      const device = obj.device_list.find(device => device.device_type_id === heading.deviceId)
      if (device) {
        if (heading.header.includes('COUNT')) {
          return device.n_devices
        }
        return device.part_num
      } else {
        if (heading.header.includes('COUNT')) {
          return 0
        }
        return '-'
      }
    }
    return _.get(obj, heading.valuePath, heading.defaultValue ? heading.defaultValue : '-')
  }

  const setRequestTable = (userData) => {
    let requestTable = []
    userData.map(obj => {
      let tableObj = []
      headings.forEach(heading => {
        let tableValues = {
          id: heading.id,
          value: getFieldValue(obj, heading),
          className: heading.id === 'systemId' ? styles.systemIdValue : heading.id === 'systemName' ? styles.systemNameValue : ''
        }
        tableObj.push(tableValues)
      })
      requestTable.push(tableObj)
      return null
    })
    setTableData(requestTable)
  }

  useEffect(() => {
    getUserDetails()
  }, [page, rowsPerPage])

  useEffect(() => {
    setHeadings(tableHeadings(columns))
  }, [columns])

  useEffect(() => {
    if (userData.length > 0)
      setRequestTable(userData)
  }, [headings])

  return (
    <Box>
      <Loader loading={loading} />
      <Breadcrumb value={[{ 'System Dashboard': '/dashboard' }]} />
      <ColumnSelector id={id} open={open} anchorEl={anchorEl} handlePopoverClose={handleClose} columns={columns} setColumns={setColumns} />
      <Box className={styles.headerWrapper} display="flex" flexWrap="wrap">
        <Box>
          <Typography className={styles.title}>{'Requests'}</Typography>
        </Box>
        <Box>
          <AppButton text="Create Request" onClick={() => history.push('/create-site-form')} />
        </Box>
      </Box>
      <Box className={styles.tableContainer}>
        <Box m={1}>
          <Box display="flex" justifyContent="space-between">
            <Box><Typography className={styles.tableHeader}>Site Dashboard</Typography></Box>
            <SpaceDashboardOutlinedIcon className={styles.columnSelectorIcon} color="primary" onClick={handleClick} />
          </Box>
          <Box my={1}>
            <TableOutline
              tableHeadings={headings}
              tableData={tableData}
              loading={loading}
              totalElement={totalCount}
              pagination={totalCount > 0}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handlePageChange}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Dashboard